import GatsbyImage from 'gatsby-image';
import React from 'react';
import LayoutDefault from '@src/layouts/default';
import { ErrorQuery } from '@types';
import { adaptImage } from '@src/adapters/image';
import { graphql, Link } from 'gatsby';
import styles from './404.module.scss';

interface ErrorProps {
  data: ErrorQuery;
}

const NotFoundPage: React.FC<ErrorProps> = ({ data }) => {
  const errorImage = adaptImage(data.errorImage);

  return (
    <LayoutDefault pageId="error">
      <div className={styles.block}>
        <div className={styles.content}>
          <div className={styles.image}>
            <GatsbyImage fluid={errorImage} fadeIn={false} />
          </div>
          <div className={styles.container}>
            <h1 className={styles.title}>404: Oups !</h1>
            <div className={styles.text}>
              <p>
                Vous venez de tomber sur une page inexistante...
                <br />
                Vous pouvez revenir sur la page d&apos;accueil en cliquant ici :
              </p>
              <Link to="/" className={styles.link}>
                Retour à l&apos;accueil
              </Link>
            </div>
          </div>
        </div>
      </div>
    </LayoutDefault>
  );
};

export const query = graphql`
  query Error {
    errorImage: file(relativePath: { eq: "error.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NotFoundPage;
